import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import { mapIndexed } from '../lib/Helpers'

library.add(fab)

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        title
        main_contact_email
        social_links {
          title
          url
          sponsor
          icon {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const {
    sanitySiteSettings: { social_links }
  } = data

  return (
    <footer>
      <div className="container">
        <h4 className="text--center title uppercase color--yellow">
          Brought to you by:
        </h4>
        <div className="sponsors">
          <img
            src={require('../assets/imgs/afwerx-logo.png')}
            alt="afwerx logo"
          />
          <div className="sponsors--logos">
            {mapIndexed((link, index) => {
              return (
                link.sponsor && (
                  <a
                    key={index}
                    href={link.url}
                    className="link link--white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={link.icon.asset.url} alt={`${link.title} icon`} />
                  </a>
                )
              )
            })(social_links)}
          </div>
        </div>
        <hr className="bg--yellow" />
        <ul className="text--center footer-links">
          {mapIndexed((link, index) => {
            return (
              !link.sponsor && (
                <li key={index}>
                  <a
                    href={link.url}
                    className="link link--white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={link.icon.asset.url} alt={`${link.title} icon`} />
                  </a>
                </li>
              )
            )
          })(social_links)}
        </ul>
        <p className="text--center">
          The appearance of external links on this site does not constitute
          official endorsement on behalf of the U.S. Air Force, U.S. Space Force
          or Department of Defense. The Air Force and Space Force do not endorse
          any non-federal government organizations, products, or services.
        </p>
      </div>
    </footer>
  )
}
