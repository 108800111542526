import * as R from 'ramda'
import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import sanityClient from '../lib/client.js'

import { notNilOrEmpty } from '../lib/Helpers'

export default props => {
  const [heroData, setHeroData] = React.useState([])
  const query = `*[_type == "homePage"] {
    ctaBtn {
      ...
    },
    hero_overlay_image {
      asset-> {
        url
      }
    },
    "live_yt_id": hero_youtube_id,
    "embed_chat": hero_youtube_chat_id
  }`

  React.useEffect(() => {
    props.fetchApi &&
      sanityClient
        .fetch(query)
        .then(res => setHeroData(res[0]))
        .catch(err => console.error)
  }, [])

  return (
    <div
      className={`hero hero--${props.className}`}
      style={{
        background: props.img
          ? `#000 url(${props.img}) center / cover no-repeat`
          : '#000'
      }}
    >
      {notNilOrEmpty(props.video) && (
        <video
          className="hero__video-bg"
          playsInline
          autoPlay
          loop
          muted
          src={props.video}
        />
      )}
      <div className="hero--overlay" />
      <div className="hero__content container">
        {props.fetchApi ? (
          notNilOrEmpty(heroData) && notNilOrEmpty(heroData.live_yt_id) ? (
            <div className="hero--live">
              <iframe
                allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                autoPlay
                allowFullScreen
                className="hero--live__video"
                frameBorder="0"
                height="480"
                src={`https://www.youtube.com/embed/${heroData.live_yt_id}`}
                title="Live Stream"
                width="720"
              />
              {heroData.embed_chat && (
                <iframe
                  allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  autoPlay
                  allowFullScreen
                  className="hero--live__chat"
                  frameBorder="0"
                  height="480"
                  src={`https://www.youtube.com/live_chat?v=${heroData.live_yt_id}&embed_domain=engage.space`}
                  title="Live Stream"
                />
              )}
            </div>
          ) : (
            notNilOrEmpty(props.overlayImg) && (
              <img
                alt="icon in the hero section"
                className={
                  R.equals(props.className, 'home-page')
                    ? 'hero__content__img'
                    : `hero--${props.className}__overlay-image`
                }
                src={props.overlayImg}
              />
            )
          )
        ) : (
          notNilOrEmpty(props.overlayImg) && (
            <img
              alt="icon in the hero section"
              className={
                R.equals(props.className, 'home-page')
                  ? 'hero__content__img'
                  : `hero--${props.className}__overlay-image`
              }
              src={props.overlayImg}
            />
          )
        )}
        {notNilOrEmpty(heroData) && notNilOrEmpty(heroData.ctaBtn.cta_btn_url) && (
          <>
            <div style={{ height: 50 }} />
            <AniLink
              cover
              to={heroData.ctaBtn.cta_btn_url}
              duration={1.25}
              direction="right"
              className="btn btn--hero-register"
              bg={`
              center / 50% /* position / size */
              no-repeat        /* repeat */
              fixed            /* attachment */
              padding-box      /* origin */
              content-box      /* clip */
              #00021a          /* color */
            `}
            >
              {heroData.ctaBtn.cta_btn_text}
            </AniLink>
          </>
        )}
        {props.title && <h3 className="hero__content__title">{props.title}</h3>}
        {props.subTitle && (
          <p className="hero__content__sub-title">{props.subTitle}</p>
        )}
      </div>
    </div>
  )
}
